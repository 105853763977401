import React from "react";
import styled from "styled-components";
import { Navbar } from "./components/Navbar";
import { Hero } from "./sections/Hero";
import { Services } from "./sections/Services";
import { Portfolio } from "./sections/Portfolio";
import { About } from "./sections/About";
import { Contact } from "./sections/Contact";
import { Footer } from "./components/Footer";

const AppContainer = styled.div`
  font-family: "Roboto", sans-serif;
  color: #333;
`;

function App() {
  return (
    <AppContainer>
      <Navbar />
      <Hero />
      <Services />
      <Portfolio />
      <About />
      <Contact />
      <Footer />
    </AppContainer>
  );
}

export default App;
