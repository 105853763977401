import styled from "styled-components";

export const Button = styled.button`
  background-color: #f3a712;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e89c0c;
  }
`;
