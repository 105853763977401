import React, { useState } from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import logo from "../assets/images/logo-medium.png";

const Nav = styled.nav`
  background: rgba(27, 31, 59, 0.8); /* Transparente */
  backdrop-filter: blur(10px); /* Desenfoque */
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  border-bottom: 2px solid #00c8ff; /* Borde celeste */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 900px) {
    justify-content: space-between;
  }
`;

const Logo = styled.img`
  height: 3em;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  /* Para pantallas de 900px o menos */
  @media (max-width: 900px) {
    display: ${({ isOpen }) =>
      isOpen
        ? "flex"
        : "none"}; /* Solo controla la visibilidad en pantallas pequeñas */
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(27, 31, 59, 0.9);
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 10px;
    transition: all 0.3s ease-in-out;
  }
`;

const NavLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
  position: relative;

  &:hover {
    color: #00c8ff; /* Color celeste en hover */
  }

  &:after {
    content: "";
    display: block;
    height: 2px;
    background: #00c8ff;
    width: 0;
    transition: width 0.3s;
    position: absolute;
    bottom: -5px;
    left: 0;
  }

  &:hover:after {
    width: 100%;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  font-size: 28px;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false); // Cerrar menú después de hacer clic en un enlace en pantallas pequeñas
  };

  return (
    <Nav>
      <Logo src={logo} alt="Monaco IT Logo" />
      {/* Icono hamburguesa para pantallas pequeñas */}
      <HamburgerIcon onClick={toggleMenu}>
        <FaBars />
      </HamburgerIcon>

      {/* Menú hamburguesa en pantallas pequeñas */}
      <NavLinks isOpen={isOpen}>
        <NavLink onClick={() => scrollToSection("hero")}>Inicio</NavLink>
        <NavLink onClick={() => scrollToSection("services")}>Servicios</NavLink>
        <NavLink onClick={() => scrollToSection("portfolio")}>
          Proyectos
        </NavLink>
        <NavLink onClick={() => scrollToSection("about")}>Nosotros</NavLink>
        <NavLink onClick={() => scrollToSection("contact")}>Contacto</NavLink>
      </NavLinks>
    </Nav>
  );
};
