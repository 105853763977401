import React from "react";
import styled from "styled-components";
import {
  FaLaptopCode,
  FaMobileAlt,
  FaBrain,
  FaPenNib,
  FaCogs,
  FaTools,
} from "react-icons/fa";

const ServicesContainer = styled.section`
  padding-top: 2em;
  background-color: #ffffff;
  text-align: center;

  @media (max-width: 900px) {
    padding: 0;
    margin-top: 1em;
  }
`;

const ServiceTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #1b1f3b;
  font-weight: bold;

  span {
    color: #00c8ff; /* Color azul para "Servicios" */
  }

  /* Línea decorativa bajo el título */
  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background-color: #00c8ff;
    margin: 10px auto;
  }

  @media (max-width: 900px) {
    margin-top: 1em;
    font-size: 1.75em;
  }
`;

const ServicesList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columnas */
  gap: 20px;
  justify-content: center;
  margin: 0 4em 0 4em;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    margin: 3em 2em 0 2em;
  }
`;

const ServiceItem = styled.div`
  background: #fff;
  padding: 30px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const ServiceIcon = styled.div`
  font-size: 48px;
  margin-bottom: 20px;
  color: #000; /* Iconos en negro */
`;

const ServiceName = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
  color: #343a40;
  position: relative;

  /* Línea decorativa bajo los títulos */
  &::after {
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #7f8c8d;
    margin: 8px auto;
  }
`;

const ServiceDescription = styled.p`
  color: #7f8c8d;
  font-size: 16px;
`;

export const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServiceTitle>
        NUESTROS <span>SERVICIOS</span>
      </ServiceTitle>
      <ServicesList>
        <ServiceItem>
          <ServiceIcon>
            <FaLaptopCode />
          </ServiceIcon>
          <ServiceName>Desarrollo Web</ServiceName>
          <ServiceDescription>
            Desarrollamos sitios web responsivos y autoadministrables que
            reflejan la identidad de tu marca y optimizan la experiencia del
            usuario.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem>
          <ServiceIcon>
            <FaMobileAlt />
          </ServiceIcon>
          <ServiceName>Apps Móviles Personalizadas</ServiceName>
          <ServiceDescription>
            Aumenta el éxito de tu negocio con aplicaciones móviles que
            faciliten la conexión entre tus clientes y tus servicios.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem>
          <ServiceIcon>
            <FaBrain />
          </ServiceIcon>
          <ServiceName>IA & Machine Learning</ServiceName>
          <ServiceDescription>
            Optimiza tu negocio con soluciones de machine learning e
            inteligencia artificial que transformen datos en decisiones
            estratégicas.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem>
          <ServiceIcon>
            <FaPenNib />
          </ServiceIcon>
          <ServiceName>UI & UX Design</ServiceName>
          <ServiceDescription>
            Eleva la satisfacción de tus usuarios optimizando la interacción y
            la experiencia, haciendo que tus productos sean intuitivos y
            atractivos.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem>
          <ServiceIcon>
            <FaCogs />
          </ServiceIcon>
          <ServiceName>Desarrollo de MVP</ServiceName>
          <ServiceDescription>
            Acelera el lanzamiento de tu idea con un MVP que valide tus
            hipótesis y te permita obtener feedback real de tus usuarios desde
            el inicio.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem>
          <ServiceIcon>
            <FaTools />
          </ServiceIcon>
          <ServiceName>Soporte & Mantenimiento</ServiceName>
          <ServiceDescription>
            Asegura el funcionamiento continuo de tus sistemas con nuestro
            servicio de soporte y mantenimiento.
          </ServiceDescription>
        </ServiceItem>
      </ServicesList>
    </ServicesContainer>
  );
};
