import React from "react";
import styled from "styled-components";

import proyectImg1 from "../assets/images/portfolio/balisolarium20.png";
import proyectImg15 from "../assets/images/portfolio/balisolarium21.png";
import proyectImg2 from "../assets/images/portfolio/habitree.png";
import proyectImg3 from "../assets/images/portfolio/mispropinas.png";
import proyectImg4 from "../assets/images/portfolio/Amador.png"
import proyectImg5 from "../assets/images/portfolio/servimatch.png";
import proyectImg6 from "../assets/images/portfolio/suenario.jpeg";

const PortfolioContainer = styled.section`
  padding-top: 4em;
  padding-bottom: 4em;
  background-color: #ffffff;
  text-align: center;
  margin-top: 0;

  @media (max-width: 900px) {
    padding: 0;
    margin-top: 2em;
    margin-bottom: 2em;
  }
`;

const PortfolioTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 40px;
  color: #1b1f3b;
  font-weight: bold;

  /* Línea decorativa y color en "Destacados" */
  span {
    color: #00c8ff;
  }

  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background-color: #00c8ff;
    margin: 10px auto;
  }

  @media (max-width: 900px) {
    font-size: 1.75em;
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 0 4em 0 4em;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    margin: 0 2em;
  }
`;

const ProjectItem = styled.div`
  position: relative;
  overflow: hidden;

  &:hover img {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }

  @media (max-width: 900px) {
    margin-bottom: 0px;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  transition: transform 0.3s ease;
`;

const ProjectOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); /* Fondo oscuro translúcido */
  color: white;
  padding: 20px;
  transition: opacity 0.3s ease;
`;

const ProjectTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const ProjectDescription = styled.p`
  font-size: 14px;
  color: #f4f4f4;

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

/*
const ViewMoreButton = styled.button`
  background-color: #00c8ff;
  color: white;
  border: none;
  padding: 15px 50px;
  font-size: 16px;
  margin-top: 40px;
  cursor: pointer;

  &:hover {
    background-color: #007c99;
  }

  @media (max-width: 900px) {
    padding: 12px 40px;
    font-size: 14px;
  }
`;
*/

export const Portfolio = () => {
  return (
    <PortfolioContainer id="portfolio">
      <PortfolioTitle>
        PROYECTOS <span>DESTACADOS</span>
      </PortfolioTitle>
      <ProjectsGrid>
        <ProjectItem>
          <ProjectImage src={proyectImg1} alt="Bali Solarium" />
          <ProjectImage src={proyectImg15} alt="Bali Solarium" />
          <ProjectOverlay>
            <ProjectTitle>Bali Solarium</ProjectTitle>
            <ProjectDescription>
              Una plataforma de e-commerce para un solarium, con un sistema de
              venta en línea de productos y sesiones para el cuidado de la piel.
            </ProjectDescription>
          </ProjectOverlay>
        </ProjectItem>
        <ProjectItem>
          <ProjectImage src={proyectImg2} alt="HabiTree" />
          <ProjectOverlay>
            <ProjectTitle>HabiTree</ProjectTitle>
            <ProjectDescription>
              Aplicación ganadora del Apple Swift Student Challenge, reconocida
              como una de las 50 mejores apps del mundo por su enfoque innovador
              en la creación de hábitos.
            </ProjectDescription>
          </ProjectOverlay>
        </ProjectItem>
        <ProjectItem>
          <ProjectImage src={proyectImg3} alt="Mis Propinas" />
          <ProjectOverlay>
            <ProjectTitle>Mis Propinas</ProjectTitle>
            <ProjectDescription>
              Una app diseñada para gestionar propinas en restaurantes,
              permitiendo a los meseros llevar un control eficiente de sus
              ingresos.
            </ProjectDescription>
          </ProjectOverlay>
        </ProjectItem>
        <ProjectItem>
          <ProjectImage src={proyectImg4} alt="Amador" />
          <ProjectOverlay>
            <ProjectTitle>Amador</ProjectTitle>
            <ProjectDescription>
              Una App de autopedidos para restaurantes, con tarjetas tokenizadas
              para facilitar pagos rápidos y seguros, mejorando la experiencia
              del cliente.
            </ProjectDescription>
          </ProjectOverlay>
        </ProjectItem>
        <ProjectItem>
          <ProjectImage src={proyectImg5} alt="ServiMatch" />
          <ProjectOverlay>
            <ProjectTitle>ServiMatch</ProjectTitle>
            <ProjectDescription>
              App que conecta a usuarios con proveedores de servicios,
              optimizando el proceso de búsqueda y contratación de servicios en
              diversas áreas.
            </ProjectDescription>
          </ProjectOverlay>
        </ProjectItem>
        <ProjectItem>
          <ProjectImage src={proyectImg6} alt="Sueñario" />
          <ProjectOverlay>
            <ProjectTitle>Sueñario</ProjectTitle>
            <ProjectDescription>
              Una app única para analizar y dar significado a los sueños,
              ayudando a los usuarios a explorar el subconsciente de manera
              intuitiva y personalizada.
            </ProjectDescription>
          </ProjectOverlay>
        </ProjectItem>
      </ProjectsGrid>
    </PortfolioContainer>
  );
};
