import React from "react";
import styled, { keyframes } from "styled-components";
import { Button } from "../components/Button";
import bgImage from "../assets/images/home-bg.png";

// Animaciones
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeroContainer = styled.section`
  height: 100vh;
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding-top: 60px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(
      0,
      0,
      0,
      0.5
    ); /* Overlay oscuro para hacer los textos más legibles */
    z-index: 1;
  }

  @media (max-width: 900px) {
    height: 100vh;
    padding-top: 80px;
  }
`;

const HeroContent = styled.div`
  padding: 50px;
  border-radius: 10px;
  max-width: 80%;
  z-index: 2; /* Para estar por encima del overlay */
  animation: ${fadeIn} 1.5s ease-out;

  @media (max-width: 900px) {
    padding: 30px;
    text-align: center;
    max-width: 100%;
  }
`;

const HeroTitle = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
  line-height: 1.2;
  background: linear-gradient(to right, #00c6d7, #005bea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${fadeIn} 1.5s ease-out;

  @media (max-width: 900px) {
    font-size: 1.75em;
    font-weight: bold;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.75em;
  margin-bottom: 40px;
  color: #f4f4f4;
  animation: ${fadeIn} 2s ease-out;

  @media (max-width: 900px) {
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 30px;
  }
`;

const PrimaryButton = styled(Button)`
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 15px 30px;
  margin-right: 20px;
  font-weight: 700;
  cursor: pointer;
  font-size: 1em;
  animation: ${fadeIn} 2.5s ease-out;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 900px) {
    margin-right: 10px;
    font-size: 0.75em;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: white;
  border: 3px solid white;
  padding: 12px 27px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1em;
  animation: ${fadeIn} 3s ease-out;
  transition: all 0.3s ease-in-out;

  @media (max-width: 900px) {
    font-size: 0.75em;
    margin-top: 1em;
  }
`;

export const Hero = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <HeroContainer id="hero">
      <HeroContent>
        <HeroTitle>
          INNOVACIÓN Y TECNOLOGÍA PARA IMPULSAR TUS PROYECTOS
        </HeroTitle>
        <HeroSubtitle>
          Ayudamos a startups y empresas a crecer con soluciones tecnológicas
          personalizadas. <br />
          Nos especializamos en transformar ideas en productos reales.
        </HeroSubtitle>
        <div>
          <PrimaryButton onClick={() => scrollToSection("services")}>
            DESCUBRE NUESTROS PROYECTOS
          </PrimaryButton>
          <SecondaryButton onClick={() => scrollToSection("contact")}>
            COTIZA TU PROYECTO
          </SecondaryButton>
        </div>
      </HeroContent>
    </HeroContainer>
  );
};
