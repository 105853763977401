import React, { useState } from "react";
import styled from "styled-components";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import bgImage from "../assets/images/contact-bg.jpeg";
import emailjs from "emailjs-com";

const ContactContainer = styled.section`
  padding: 80px 20px;
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center;
  color: white;
  justify-content: space-between;
  align-items: center;
  margin-top: 5em;

  @media (max-width: 900px) {
    padding: 0;
    padding-top: 5em;
    padding-bottom: 5em;
  }
`;

const ContactSides = styled.div`
  display: flex;
  margin: 0 4em 0 4em;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

const ContactLeft = styled.div`
  flex: 1;
  padding-right: 40px;

  @media (max-width: 900px) {
    text-align: center;
    margin-bottom: 2em;
    padding: 0 20px 0 20px;
  }
`;

const ContactTitle = styled.h2`
  font-size: 27px;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3em;

  &::after {
    content: "";
    display: block;
    width: 200px;
    height: 3px;
    background-color: #cccccc;
    margin: 10px auto;
  }

  span {
    color: #00c6d7;
  }
`;

const ContactText = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #ffffff;

  @media (max-width: 900px) {
    font-size: 16px;
    text-align: left;
  }
`;

const ContactTextUp = styled.p`
  font-size: 22px;
  line-height: 1.6;
  font-weight: 700;
  color: #ffffff;

  @media (max-width: 900px) {
    font-size: 20px;
    text-align: left;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
    color: #00c8ff;
  }

  span {
    font-size: 16px;

    @media (max-width: 900px) {
      font-size: 14px;
    }
  }
`;

const ContactRight = styled.div`
  flex: 1;

  @media (max-width: 900px) {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const ContactForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const Input = styled.input`
  padding: 15px;
  font-size: 16px;
  border: none;
  background-color: #ffffff;
  color: #333;
  grid-column: span ${(props) => (props.fullwidth ? 2 : 1)};

  @media (max-width: 900px) {
    grid-column: span 1;
  }
`;

const TextArea = styled.textarea`
  padding: 15px;
  font-size: 16px;
  border: none;
  background-color: #ffffff;
  color: #333;
  grid-column: span 2;
  height: 150px;
  resize: none;

  @media (max-width: 900px) {
    grid-column: span 1;
  }
`;

const SubmitButton = styled.button`
  grid-column: span 2;
  background-color: #00c8ff;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #00a1d6;
  }

  @media (max-width: 900px) {
    grid-column: span 1;
  }
`;

const SuccessMessage = styled.p`
  color: #00ff00;
  text-align: center;
  margin-top: 20px;
`;

export const Contact = () => {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState(""); // Estado para el mensaje de éxito

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_hz3vepe",
        "template_19hrrqk",
        formData,
        "vEpAM_4evm5xbt-Zm"
      )
      .then(
        (result) => {
          console.log("Email enviado:", result.text);
          setSuccessMessage("¡Mensaje enviado con éxito!"); // Mensaje de éxito
          setFormData({
            email: "",
            subject: "",
            message: "",
          }); // Reinicia los campos del formulario
        },
        (error) => {
          console.log("Error al enviar email:", error.text);
        }
      );
  };

  return (
    <ContactContainer id="contact">
      <ContactTitle>
        EN <span>CONTACTO</span>
      </ContactTitle>
      <ContactSides>
        <ContactLeft>
          <ContactTextUp>HABLEMOS</ContactTextUp>
          <ContactText>
            Conéctate fácilmente con nosotros a través de nuestro formulario de
            contacto. Completa tu correo, elige un asunto y escribe tu mensaje.
            ¡Estamos aquí para ayudarte!
          </ContactText>
          <ContactInfo>
            <FaEnvelope />
            <span>mseguel@monaco-it-services.cl</span>
          </ContactInfo>
          <ContactInfo>
            <FaPhone />
            <span>+56 9 5197 0995</span>
          </ContactInfo>
        </ContactLeft>

        <ContactRight>
          <ContactForm onSubmit={sendEmail}>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={formData.email}
            />
            <Input
              type="text"
              name="subject"
              placeholder="Asunto"
              onChange={handleChange}
              value={formData.subject}
            />
            <TextArea
              name="message"
              placeholder="Tu mensaje"
              onChange={handleChange}
              value={formData.message}
            />
            <SubmitButton type="submit">Enviar Mensaje</SubmitButton>
          </ContactForm>
          {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}{" "}
        </ContactRight>
      </ContactSides>
    </ContactContainer>
  );
};
