import React from "react";
import styled from "styled-components";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import logo from "../assets/images/logo-high.png";

const FooterContainer = styled.footer`
  background: #3b3b3b;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-top: 3px solid #00c8ff;
  margin-top: 3em;
  padding: 1em 4em 1em 4em;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 2em;
    align-items: center;
  }
`;

const FooterLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: left;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }

  img {
    height: 8em;

    @media (max-width: 768px) {
      margin-bottom: 1em;
    }
  }
`;

const FooterCenter = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const FooterRight = styled.div`
  flex: 1;
  text-align: right;
  align-items: end;

  @media (max-width: 768px) {
    text-align: center;
  }

  p {
    margin: 5px 0;
    font-size: 12px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    align-items: center;
  }

  div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    svg {
      margin-right: 10px;
      color: #00c8ff; /* Color de los iconos */
    }
  }
`;

const FooterBottom = styled.div`
  align-items: end;
  margin-top: 20px;
  padding-top: 20px;
  width: 100%;
  font-size: 12px;

  @media (max-width: 768px) {
    padding-top: 0;
    text-align: center;
  }

  span {
    color: #00c8ff; /* Color para resaltar */
  }
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterLeft>
        <img src={logo} alt="Monaco IT Services Logo" />
      </FooterLeft>
      <FooterCenter>
        <ContactInfo>
          <div>
            <FaPhone />
            <p>+56 9 5197 0995</p>
          </div>
          <div>
            <FaEnvelope />
            <p>mseguel@monaco-it-services.cl</p>
          </div>
        </ContactInfo>
      </FooterCenter>
      <FooterRight>
        <FooterBottom>
          <p>
            © 2024. Todos los derechos reservados a{" "}
            <span>Monaco IT Services</span>
          </p>
        </FooterBottom>
      </FooterRight>
    </FooterContainer>
  );
};
