import React from "react";
import styled from "styled-components";
import officeImage from "../assets/images/me.jpeg";

const AboutContainer = styled.section`
  padding: 80px 20px;
  background-color: #3b3b3b;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    padding: 60px 20px 0 20px;
  }
`;

const AboutContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: left;
  margin: 0 4em 0 4em;

  @media (max-width: 900px) {
    text-align: center;
    flex-direction: column;
    margin: 0;
  }
`;

const AboutTextContainer = styled.div`
  flex: 1;
  padding: 20px;

  @media (max-width: 900px) {
    text-align: left;
    padding: 20px 0 4em 0;
  }
`;

const AboutImage = styled.img`
  flex: 1;
  max-width: 45%;
  height: auto;

  @media (max-width: 900px) {
    max-height: 300px;
    max-width: 100%;
  }
`;

const AboutTitle = styled.h2`
  font-size: 27px;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2em;

  &::after {
    content: "";
    display: block;
    width: 75%;
    height: 3px;
    background-color: #cccccc;
    margin: 10px auto;
  }

  span {
    color: #00c6d7;
  }

  @media (max-width: 900px) {
    font-size: 24px;
  }
`;

const AboutSubtitle = styled.h3`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;

  span {
    color: #337ab7;
  }

  @media (max-width: 900px) {
    font-size: 1.75em;
  }
`;

const AboutText = styled.p`
  color: #f4f4f4;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  max-width: 800px;

  @media (max-width: 900px) {
    font-size: 16px;
    max-width: 100%;
    text-align: justify;
  }
`;

const AboutTextUp = styled.h1`
  color: #f4f4f4;
  font-size: 20px;
  line-height: 2;
  max-width: 800px;
  font-weight: 700;

  @media (max-width: 900px) {
    line-height: 1.5;
    font-size: 20px;
    padding-bottom: 10px;
    padding-right: 0px;
  }
`;

export const About = () => {
  return (
    <AboutContainer id="about">
      <AboutTitle>
        SOBRE <span>MARTÍN SEGUEL</span>
      </AboutTitle>
      <AboutContent>
        <AboutImage src={officeImage} alt="Oficina" />
        <AboutTextContainer>
          <AboutSubtitle>
            FUNDADOR DE <span>MONACO IT SERVICES</span>
          </AboutSubtitle>
          <AboutTextUp>
            Ingeniero Civil Informático & Desarrollador de Software
          </AboutTextUp>
          <AboutText>
            Nací y crecí en Los Ángeles, Chile, y desde pequeño siempre tuve una gran
            pasión por la tecnología y la programación. Estudié Ingeniería Civil
            Informática en la Universidad Adolfo Ibáñez, donde fortalecí mis
            habilidades en desarrollo de software. Durante mis años de estudio,
            fui uno de los 50 ganadores del prestigioso Apple Swift Challenge,
            convirtiéndome en el primer chileno en recibir este reconocimiento
            internacional.
            <br />
            <br />
            Impulsado por ese logro, decidí fundar{" "}
            <strong>MONACO IT SERVICES</strong>, una consultora enfocada en
            desarrollar soluciones tecnológicas innovadoras para startups y empresas
            que buscan potenciar su crecimiento a través de software a medida. 
            Hoy en día, lidero proyectos junto a un equipo talentoso de diseñadores y
            desarrolladores, creando desde aplicaciones móviles hasta plataformas web y
            soluciones basadas en inteligencia artificial.
            <br />
            <br />
            Mi misión es ayudar a startups a crecer utilizando las mejores
            herramientas tecnológicas, combinando creatividad y experiencia para
            ofrecer productos que realmente marquen la diferencia en sus
            respectivos mercados.
          </AboutText>
        </AboutTextContainer>
      </AboutContent>
    </AboutContainer>
  );
};
